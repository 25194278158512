<template>
  <paginated-search-select
    :selected="selectedEnterprise"
    :get-label="getLabel"
    :options="enterprises"
    :template="template"
    placeholder="Search enterprises..."
    @change="updateSelectedEnterprise"
    @change-search="updateEnterpriseFilter"
  />
</template>

<script>
import EnterpriseService from "@/services/EnterpriseService";
import EnterpriseTemplate from "@/components/input/CAutoCompleteTemplates/EnterpriseTemplate.vue";
import PaginatedSearchSelect from "@/components/input/PaginatedSearchSelect/PaginatedSearchSelect.vue";

export default {
  name: "EnterpriseSearchSelect",
  components: { PaginatedSearchSelect },
  props: {
    selected: {
      type: Object,
      default: null,
    }
  },
  data() {
    return {
      loading: true,
      selectedEnterprise: this.$props.selected,
      enterprises: [],
      enterpriseFilters: {
        page: 0,
        page_size: 15,
        search: "",
      },
      template: EnterpriseTemplate,
    }
  },
  watch: {
    loading: {
      immediate: true,
      handler(newVal) {
        if(newVal) {
          this.$emit('loading');
        } else {
          this.$emit('loaded');
        }
      }
    },
    selected: {
      deep: true,
      handler() {
        this.selectedEnterprise = this.$props.selected;
      }
    },
  },
  mounted() {
    this.getEnterprises();
  },
  methods: {
    getMoreEnterprises(page) {
      this.enterpriseFilters.page = page - 1;
      this.getEnterprises();
    },
    async getEnterprises() {
      this.loading = true;
      try {
        const res = await EnterpriseService.getEnterprises(this.enterpriseFilters);
        this.enterprises = res.data.data;
      } catch (err) {
        const res = err.response;
        let errorText = "Could not get enterprises, please refresh and try again";

        if (res && res.data.error) {
          errorText = res.data.error;
        }

        this.$toast.error(errorText, {
          toastClassName: ["toast-std", "warning-toast"],
        });
      } finally {
        this.loading = false;
      }
    },
    getLabel(enterprise) {
      if (!enterprise) return "";
      return enterprise.display_name;
    },
    updateSelectedEnterprise(enterprise) {
      this.$emit("change", enterprise);
    },
    updateEnterpriseFilter(search) {
      this.enterpriseFilters.search = search;
      this.getMoreEnterprises(1);
    },
  },
};
</script>

<style scoped lang="scss"></style>
