<template>
  <section>
    <label>Enterprise</label>
    <enterprise-search-select
      :selected="selectedEnterprise"
      @change="updateSelectedEnterprise"
    />

    <section class="d-inline-flex full-width mt-4">
      <div class="ui-spacer" />
      <b-button class="mr-2" @click="$emit('close')">Cancel</b-button>
      <b-button
        type="submit"
        variant="primary"
        :disabled="!selectedEnterprise"
        @click="$emit('update', selectedEnterprise);"
      >
        Update Session Type
      </b-button>
    </section>
  </section>
</template>

<script>
import EnterpriseSearchSelect from "@/components/input/PaginatedSearchSelect/EnterpriseSearchSelect.vue";

export default {
  name: "AddEnterpriseModal",
  components: { EnterpriseSearchSelect },
  data() {
    return {
      selectedEnterprise: null,
    };
  },
  methods: {
    updateSelectedEnterprise(enterprise) {
      this.selectedEnterprise = enterprise;
    },
  },
};
</script>

<style scoped lang="scss"></style>
