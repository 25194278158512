import HelperService from "@/services/HelperService";
import axios from "@/libs/axios";

const baseUrl = "/admin/scribe";

export default {
  listJobTypes(filters) {
    const queryString = HelperService.buildSearchQueryString(filters);
    return axios.get(`${baseUrl}/job-types${queryString}`);
  },
  getJobType(id) {
    return axios.get(`${baseUrl}/job-types/${id}`);
  },
  createJobType(dto) {
    return axios.post(`${baseUrl}/job-types`, dto);
  },
  updateJobType(id, dto) {
    return axios.put(`${baseUrl}/job-types/${id}`, dto);
  },
  deleteJobType(id) {
    return axios.delete(`${baseUrl}/job-types/${id}`);
  },
  listSummaryTypes(filters) {
    const queryString = HelperService.buildSearchQueryString(filters);
    return axios.get(`${baseUrl}/summary-types${queryString}`);
  },
  getSummaryType(id) {
    return axios.get(`${baseUrl}/summary-types/${id}`);
  },
  createSummaryType(dto) {
    return axios.post(`${baseUrl}/summary-types`, dto);
  },
  updateSummaryType(id, dto) {
    return axios.put(`${baseUrl}/summary-types/${id}`, dto);
  },
  deleteSummaryType(id) {
    return axios.delete(`${baseUrl}/summary-types/${id}`);
  },
};
