import { render, staticRenderFns } from "./UpdateScribeSummaryTypeModal.vue?vue&type=template&id=e331a8a4&scoped=true"
import script from "./UpdateScribeSummaryTypeModal.vue?vue&type=script&lang=js"
export * from "./UpdateScribeSummaryTypeModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e331a8a4",
  null
  
)

export default component.exports