<template>
  <div v-if="editSummaryType" class="update-scribe-modal">
    <validation-observer ref="observer" v-slot="{ handleSubmit }">
      <b-form @submit.stop.prevent="handleSubmit(updateSummaryType)">
        <section class="mb-1">
          <label>Name</label>
          <validation-provider
            v-slot="validationContext"
            :rules="{ required: true }"
            name="Name"
          >
            <b-form-input
              v-model="editSummaryType.name"
              :state="getValidationState(validationContext)"
              placeholder="Enter summary type name..."
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </section>

        <section class="mb-1">
          <label>Display Name</label>
          <validation-provider
            v-slot="validationContext"
            :rules="{ required: true }"
            name="Display Name"
          >
            <b-form-input
              v-model="editSummaryType.display_name"
              :state="getValidationState(validationContext)"
              placeholder="Enter summary type display name..."
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </section>

        <section class="mb-1">
          <label>Global</label>
          <b-form-checkbox
            v-model="editSummaryType.global"
            name="check-button"
            switch
          />
        </section>

        <section class="d-inline-flex full-width mt-2">
          <div class="ui-spacer" />
          <b-button class="mr-2" @click="$emit('close')">Cancel</b-button>
          <b-button type="submit" variant="primary">Update Summary Type</b-button>
        </section>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
export default {
  name: "UpdateScribeSummaryTypeModal",
  props: {
    summaryType: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      editSummaryType: null,
    };
  },
  mounted() {
    this.editSummaryType = {
      name: this.$props.summaryType.name,
      display_name: this.$props.summaryType.display_name,
      global: this.$props.summaryType.global,
      enterprise_uids: this.$props.summaryType.enterprises.map(e => e.uid),
    };
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    updateSummaryType() {
      this.$emit("update", this.editSummaryType);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
