<template>
  <div class="network-page">
    <div v-if="summaryType" class="row">
      <div class="col-12">
        <b-card title="Purple Scribe - Session Details">
          This page contains details on your Purple Scribe summary type.
        </b-card>
      </div>

      <div class="col-9">
        <b-card title="Details">
          <div class="row">
            <table class="table table-striped">
              <tbody>
                <tr>
                  <td class="pr-1">Name</td>
                  <td>{{ summaryType.name }}</td>
                </tr>
                <tr>
                  <td class="pr-1">Display Name</td>
                  <td>{{ summaryType.display_name }}</td>
                </tr>
                <tr>
                  <td class="pr-1">Global</td>
                  <td>{{ summaryType.global ? "Yes" : "No" }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-card>
      </div>

      <div class="col-3">
        <b-card title="Actions">
          <b-button
            class="full-width mb-1"
            variant="primary"
            @click="$bvModal.show('modal-update-scribe-summary-type')"
          >
            Update
          </b-button>
          <b-button
            class="full-width mb-1"
            variant="danger"
            @click="showDeleteModal"
          >
            Delete
          </b-button>
        </b-card>
      </div>

      <div class="col-12">
        <b-card>
          <section v-if="!summaryType.global">
            <div class="d-flex align-items-center">
              <b-card-title class="flex-grow-1">Enterprises</b-card-title>
              <b-button
                class="mb-1"
                variant="primary"
                @click="$bvModal.show('modal-add-enterprise')"
              >
                Add Enterprise
              </b-button>
            </div>
            <b-table
              :fields="enterpriseHeaders"
              :items="summaryType.enterprises"
              class="data-table"
              hover
            >
              <template #cell(view)="data">
                <router-link
                  :to="{
                    name: 'enterprise-view',
                    params: { uid: data.item.uid },
                  }"
                  class="font-weight-bold d-block text-nowrap"
                >
                  View enterprise
                </router-link>
              </template>
              <template #cell(remove)="data">
                <div
                  class="text-danger font-weight-bold d-block text-nowrap"
                  @click="showRemoveEnterpriseModal(data.item.uid)"
                >
                  Remove
                </div>
              </template>
            </b-table>

            <b-row>
              <p
                v-if="summaryType.enterprises.length === 0"
                class="col-6 ml-2 my-1"
              >
                No records
              </p>
            </b-row>
          </section>

          <section v-else>
            <b-card-title class="flex-grow-1">Enterprises</b-card-title>
            <b-card-text>
              This summary type is available to all enterprises.
            </b-card-text>
          </section>
        </b-card>
      </div>
    </div>

    <b-modal id="modal-update-scribe-summary-type" hide-footer size="md">
      <update-scribe-summary-type-modal
        :summary-type="summaryType"
        @close="closeModals()"
        @update="updateSummaryType"
      />
    </b-modal>

    <b-modal
      id="modal-add-enterprise"
      title="Add Enterprise"
      hide-footer
      size="md"
    >
      <add-enterprise-modal
        @update="addEnterprise"
        @close="closeModals"
      />
    </b-modal>
  </div>
</template>

<script>
import AdminPurpleScribeService from "@/services/AdminPurpleScribeService";
import AddEnterpriseModal from "@/views/adminPurpleScribe/sections/AddEnterpriseModal.vue";
import UpdateScribeSummaryTypeModal from "@/views/adminPurpleScribe/sections/UpdateScribeSummaryTypeModal.vue";

export default {
  name: "ViewAdminPurpleScribeSummaryType",
  components: { AddEnterpriseModal, UpdateScribeSummaryTypeModal },
  data() {
    return {
      summaryType: null,
      loading: false,
      enterpriseHeaders: [
        {
          key: "display_name",
          label: "Name",
          class: "col-4",
        },
        {
          key: "name",
          label: "Name",
          class: "col-4",
        },
        {
          key: "uid",
          label: "UID",
        },
        {
          key: "view",
          label: "",
        },
        {
          key: "remove",
          label: "",
        },
      ],
    };
  },
  mounted() {
    this.getSummaryType();
  },
  methods: {
    async getSummaryType() {
      this.loading = true;
      try {
        const res = await AdminPurpleScribeService.getSummaryType(
          this.$route.params.id
        );
        this.summaryType = res.data;
      } catch (err) {
        const res = err.response;
        let errorText =
          "Could not get Purple Scribe summary type, please refresh and try again";

        if (res && res.data.error) {
          errorText = res.data.error;
        }

        this.$toast.error(errorText, {
          toastClassName: ["toast-std", "warning-toast"],
        });
      } finally {
        this.loading = false;
      }
    },
    async updateSummaryType(summaryType) {
      this.loading = true;

      try {
        const res = await AdminPurpleScribeService.updateSummaryType(
          this.$route.params.id,
          summaryType
        );
        this.summaryType = res.data;

        this.$toast.success("Summary type was successfully updated.", {
          toastClassName: ["toast-std", "success-toast"],
        });

        this.closeModals()
      } catch (err) {
        const res = err.response;
        let errorText =
          "Could not update Purple Scribe summary type, please refresh and try again";

        if (res && res.data.error) {
          errorText = res.data.error;
        }

        this.$toast.error(errorText, {
          toastClassName: ["toast-std", "warning-toast"],
        });
      } finally {
        this.loading = false;
      }
    },
    async deleteSummaryType() {
      this.loading = true;
      try {
        await AdminPurpleScribeService.deleteSummaryType(this.$route.params.id);
        this.$router.push({ name: "admin-purple-scribe-summary-types" });
        this.$toast.success("Summary type was successfully deleted.", {
          toastClassName: ["toast-std", "success-toast"],
        });
      } catch (err) {
        const res = err.response;
        let errorText =
          "Could not delete Purple Scribe summary type, please refresh and try again";

        if (res && res.data.error) {
          errorText = res.data.error;
        }

        this.$toast.error(errorText, {
          toastClassName: ["toast-std", "warning-toast"],
        });
      } finally {
        this.loading = false;
      }
    },
    showDeleteModal() {
      this.$bvModal
        .msgBoxConfirm(
          "Are you sure you want to delete this summary type? This action cannot be undone.",
          {
            title: "Delete Summary Type",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "Delete",
            cancelTitle: "Cancel",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then(value => {
          if (value) {
            this.deleteSummaryType();
          }
        });
    },
    closeModals() {
      this.$bvModal.hide("modal-update-scribe-summary-type");
      this.$bvModal.hide("modal-add-enterprise");
    },
    addEnterprise(selectedEnterprise) {
      let enterpriseUids = this.summaryType.enterprises.map(e => e.uid);
      if (enterpriseUids.includes(selectedEnterprise.uid)) {
        this.$toast.error("The selected enterprise already has access to this summary type.", {
          toastClassName: ["toast-std", "warning-toast"],
        });
        return
      }
      enterpriseUids.push(selectedEnterprise.uid);
      const dto = {
        name: this.summaryType.name,
        display_name: this.summaryType.display_name,
        global: this.summaryType.global,
        enterprise_uids: enterpriseUids,
      }
      this.updateSummaryType(dto);
    },
    showRemoveEnterpriseModal(selectedUid) {
      this.$bvModal
        .msgBoxConfirm(
          "Are you sure you want to remove this enterprise?",
          {
            title: "Remove Enterprise",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "Remove",
            cancelTitle: "Cancel",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then(value => {
          if (value) {
            this.removeEnterprise(selectedUid);
          }
        });
    },
    removeEnterprise(selectedUid) {
      const dto = {
        name: this.summaryType.name,
        display_name: this.summaryType.display_name,
        global: this.summaryType.global,
        enterprise_uids: this.summaryType.enterprises
          .map(e => e.uid)
          .filter(uid => uid !== selectedUid),
      };
      this.updateSummaryType(dto);
    },
  },
};
</script>

<style lang="scss" scoped></style>
